<template>
  <div class="assessBox">
    <div class="app-container">
      <div class="titlebox">
        <el-button size="medium"
                   type="text"
                   @click="$router.go(-1)">返回</el-button>
        <div class="title">{{Viewform.name}}的评价选项详情</div>
      </div>
      <div class="viewbox">
        <div>
          <div style="display:inline-block;padding: 10px 0 0 0;"><label>评价选项</label>&nbsp;
            <el-tooltip content="拖拽可改变顺序"
                        placement="top"
                        effect="light">
              <i class="el-icon-info"></i>
            </el-tooltip>
          </div>
          <el-button style="float:right"
                     type="primary"
                     @click="handleAdd">添加</el-button>
          <el-divider></el-divider>
        </div>
        <div>
          <div class="content"
               v-for="(item,index) in optionList"
               v-dragging="{ item: item, list: optionList, group: 'option'}"
               :key="index">
            <div class="editBtn">
              <el-button type="text"
                         @click="handleEdit(item)">编辑</el-button>
            </div>
            <div><label>选项得分：{{item.score}}分</label></div>
            <div><label>选项内容：</label></div>
            <div>{{item.content}}</div>
          </div>
        </div>
        <el-dialog :title="optionTitle"
                   :visible.sync="optionDialog"
                   width="600px">
          <div style="padding:0px 50px;">
            <el-form label-width="80px"
                     ref="optionForm"
                     :rules="optionRules"
                     :model="optionForm">
              <el-form-item label="指标"
                            prop="name">
                <el-input :disabled="true"
                          v-model="optionForm.name"></el-input>
              </el-form-item>
              <el-form-item label="选项得分"
                            prop="score">
                <el-input onKeypress="return(/[\d]/.test(String.fromCharCode(event.keyCode)))"
                          type="number"
                          v-model="optionForm.score"></el-input>
              </el-form-item>
              <el-form-item label="选项内容"
                            prop="content">
                <el-input type="textarea"
                          :rows="2"
                          v-model="optionForm.content"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <span slot="footer"
                class="dialog-footer">
            <el-button @click="optionDialog=false">取 消</el-button>
            <el-button type="primary"
                       @click="submit">确 定</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import * as api from '@/api/peopelPostMatch';
export default {
  data () {
    return {
      Viewform: {
        id: '',
        name: ''
      },
      optionList: [],
      optionTitle: '',
      optionDialog: false,
      optionForm: {
        'id': '',
        'name': '',
        'content': '',
        'score': '',
        'indicatorId': ''
      },
      optionRules: {
        content: { required: true, message: '请输入内容', trigger: 'blur' },
        score: { required: true, message: '请输入选项得分', trigger: 'blur' }
      }
    };
  },
  methods: {
    getOptionList () {
      api.getOptionList({ request: { indicatorId: this.$route.query.id } }).then((res) => {
        this.optionList = res;
      });
    },
    handleAdd () {
      this.optionTitle = '添加选项';
      this.optionDialog = true;
      this.optionForm.name = this.Viewform.name;
      this.optionForm.score = '';
      this.optionForm.content = '';
      this.optionForm.id = '';
      this.optionForm.indicatorId = this.Viewform.id;
    },
    handleEdit (item) {
      this.optionDialog = true;
      this.optionForm = Object.assign(this.optionForm, item);
      this.optionForm.name = this.Viewform.name;
    },
    submit () {
      this.$refs['optionForm'].validate((valid) => {
        if (valid) {
          api.optionSave({ request: this.optionForm }).then((res) => {
            if (res.code == 0) {
              this.$message.success('添加成功');
              this.optionDialog = false;
              this.getOptionList();
            }
          });
        }
      });
    },
    optionMove (list) {
      api.optionMove({ request: { optionList: list } });
    }
  },
  mounted () {
    this.Viewform.id = this.$route.query.id;
    this.Viewform.name = this.$route.query.name;
    this.getOptionList();

    this.$dragging.$on('dragged', ({ value }) => {
      this.optionMove(value.list);
      // console.log(value.list)
    });
  }
};
</script>

<style lang="scss">
.assessBox .app-container .qualityevaluation .Postproposal_input {
  margin-top: 20px;
}
.assessBox .app-container .qualityevaluation .evaluator div span {
  font-weight: bold;
}
// 修改左边原始评估样式
.container {
  background-color: #fff;
  .divider_line {
    width: 100%;
    height: 30px;
    background-color: #f6fbf7;
    margin-bottom: 20px;
  }
  .container_box {
    .el-radio__input.is-disabled + span.el-radio__label {
      color: #606266;
    }
  }
}
</style>
<style lang="scss"  scoped>
.viewbox {
  .editBtn {
    float: right;
  }
  .content {
    margin-top: 20px;
    line-height: 25px;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
<style lang="scss" >
.assessBox {
  .app-container {
    color: #484848;
    padding: 0;
    background-color: #f6f7fb;
    .tw24 {
      display: block;
      // position: relative;
      // top: -24px;
    }
    > .el-button {
      float: left;
      color: #484848;
      transform: translateY(-8px);
      font-size: 16px;
    }
    .titlebox {
      text-align: center;
      line-height: 17px;
      background: #fff;
      font: bold 18px/25px "Microsoft YaHei";
      padding: 14px 40px;
      margin: 20px;
      > .el-button {
        float: left;
        color: #484848;
        transform: translateY(-8px);
        font-size: 16px;
      }
    }
    .viewbox {
      padding: 10px 30px;
      margin: 0 20px;
      background: #fff;
      .editBtn {
        float: right;
      }
      .content {
      }
    }
  }
}
</style>


<style lang="scss">
.assessBox .app-container .viewbox .level_box_now {
  font-weight: bold;
}
.assessBox .app-container .viewbox .level_box_old {
  font-weight: bold;
}
</style>

